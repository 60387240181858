import React from "react"

const Title = ({
  children,
  preTitle = false,
  smallTitle = false,
  singlePage = false,
  colorClass,
  className,
}) => {
  return (
    <>
      {smallTitle && (
        <h3
          className={`${
            colorClass ? colorClass : "text-heading"
          } text-2xl lg:text-4xl lg:leading-[54px] ${className}`}
        >
          {children}
        </h3>
      )}
      {preTitle && (
        <div className="mb-[10px]">
          <h2
            className={`font-body uppercase ${
              colorClass ? colorClass : "text-secondary"
            } text-[17px] leading-[25.5px] md:text-sm md:leading-[24px] md:tracking-[5px] ${className}`}
          >
            {children}
          </h2>
        </div>
      )}

      {!smallTitle && !preTitle && !singlePage && (
        <div className="mb-[10px] md:my-[10px]">
          <h2
            className={`${
              colorClass ? colorClass : "text-heading"
            } text-[29px] font-bold md:font-normal leading-[37.7px] md:text-[46px] md:leading-[60px] md:tracking-[-1.5px]  ${className}`}
          >
            {children}
          </h2>
        </div>
      )}

      {!smallTitle && !preTitle && singlePage && (
        <div className="mb-[10px] md:my-[10px]">
          <h1
            className={`${
              colorClass ? colorClass : "text-heading"
            } text-[29px] font-bold md:font-normal leading-[37.7px] md:text-[46px] md:leading-[60px] md:tracking-[-1.5px]  ${className}`}
          >
            {children}
          </h1>
        </div>
      )}
    </>
  )
}

export default Title
